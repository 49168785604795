export class StatusObject {
  public rtmChannelJoined: boolean;
  public rtmLoggedIn: boolean;
  public rtcChannelJoined: boolean;

  constructor() {
    this.rtmChannelJoined = false;
    this.rtmLoggedIn = false;
    this.rtcChannelJoined = false;
  }
}
