import { RtmChannel, RtmClient } from "agora-rtm-react";
import { useEffect } from "react";
import { StatusObject } from "../helpers/StatusObject";

export const ChannelForm = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  appId: string;
  token: string | null;
  uid: string;
  remoteUid: string;
  rtmClient: RtmClient;
  rtmChannel: RtmChannel | null;
  setRtmChannel: React.Dispatch<React.SetStateAction<RtmChannel | null>>;
  firebaseToken: string;
  setFirebaseToken: React.Dispatch<React.SetStateAction<string>>;
  statusObject: StatusObject;
}) => {
  const {
    setInCall,
    appId,
    token,
    uid,
    remoteUid,
    rtmClient,
    rtmChannel,
    setRtmChannel,
    firebaseToken,
    setFirebaseToken,
    statusObject,
  } = props;

  useEffect(() => {
    if (rtmChannel !== null && rtmChannel.channelId !== "") {
      // Need to close the call.
    }
  }, []);

  const beginCallInitiation = async () => {
    try {
      await initRtm();
    } catch (error) {
      console.error("Init RTM Error: " + error);
    }
  };

  const initRtm = async () => {
    const channelName = uid + remoteUid;
    await loginRtm(channelName);
    const rtmChan = await joinChannelRtm(channelName);
    const fbToken = await updateFirebaseToken();
    if (fbToken) {
      inviteWithFirebase(fbToken, channelName, rtmChan);
    }
    //await inviteWithRtm(options.remoteUid + "", options.channelName);
  };

  const loginRtm = async (channelName: string) => {
    await rtmClient.login({ uid: uid });
    statusObject.rtmLoggedIn = true;
    console.log("RTM Logged in");
    return;
  };

  const inviteWithFirebase = (fbToken: string, channelName: string, rtmChan: RtmChannel) => {
    try {
      const firebaseFunctionsURL = "https://europe-west1-buzz-ar-assistant.cloudfunctions.net";
      var url = `${firebaseFunctionsURL}/sendCallInvitation?uid=${remoteUid}&peer=${uid}&channel=${channelName}`;

      console.log("invite url:" + url);
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", "Bearer " + fbToken);
      //xhr.setRequestHeader("foo12", "bar12");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log(xhr.status);
            console.log(xhr.responseText);
            console.log("Firebase invitation sent to remoteUid: " + remoteUid);
            setInCall(true);
            waitForAnswer(fbToken, rtmChan);
          } else {
            console.error("Firebase invitation sending failed status code: " + xhr.status);
          }
        }
      };

      xhr.send();
      console.log("Firebase invitation sending... to remoteUid: " + remoteUid);
    } catch (error) {
      console.error("Firebase invitation sending failed: " + error);
    }
  };

  const waitForAnswer = (fToken: string, rtmChan: RtmChannel) => {
    if (!Date.now) {
      Date.now = function () {
        return new Date().getTime();
      };
    }
    //const sleep = ms => new Promise(r => setTimeout(r, ms));

    const t0 = Date.now();
    const waitTimeout = 30000;
    const url = `https://europe-west1-buzz-ar-assistant.cloudfunctions.net/getStatus?uid=${remoteUid}`;
    let status = "";

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Authorization", "Bearer " + fToken);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
        status = JSON.parse(xhr.response).status;
        console.log("Firebase waitForAnswer status:" + status);
        if (status === "cancel") {
          endCall(rtmChan);
        }

        if (Date.now() < t0 + waitTimeout && status === "ring") {
          setTimeout(waitForAnswer, 1000, fToken, rtmChan);
        }
      }
    };

    xhr.send();
    //sleep(1000);
  };

  const endCall = (rtmChan: RtmChannel) => {
    leaveRTM(rtmChan);
    setInCall(false);
  };

  const logoutRTM = async () => {
    statusObject.rtmLoggedIn = false;
    return rtmClient.logout();
  };

  const leaveRTM = async (rtmChan: RtmChannel) => {
    await leaveChannelRTM(rtmChan);
    await logoutRTM();
  };

  const leaveChannelRTM = async (rtmChan: RtmChannel) => {
    console.log("leaveChannelRTM");
    if (rtmChan !== null && statusObject.rtmChannelJoined) {
      await rtmChan
        .leave()
        .then(() => {
          console.log("leaveChannelRTM left succ");
          statusObject.rtmChannelJoined = false;
        })
        .catch(() => {
          console.error("leaveChannelRTM left fail");
        });
      //await rtmClient.logout();
    }
  };

  const joinChannelRtm = async (channelName: string) => {
    console.log("joinChannel:", channelName);
    const channel = rtmClient.createChannel(channelName);
    setRtmChannel(channel);

    subscribeChannelEvents(channel);
    channel.join().then(() => {
      console.log("Channel joined");
      statusObject.rtmChannelJoined = true;
    });

    return channel;
  };

  const subscribeChannelEvents = (rtmChan: RtmChannel) => {
    rtmChan.on("ChannelMessage", (...args) => {
      console.log("emit ", "ChannelMessage", args);
    });
    rtmChan.on("MemberJoined", (...args) => {
      console.log("emit ", "MemberJoined", args);
    });
    rtmChan.on("MemberLeft", (...args) => {
      console.log("emit ", "MemberLeft", args);
    });
  };

  const makeRequest = (method: string, url: string) => {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send();
    });
  };

  const updateFirebaseToken = async () => {
    let result = "";
    try {
      const fToken = await getFirebaseToken();
      if (fToken) {
        setFirebaseToken(fToken);
        result = fToken;
      }
      console.log("Retrieved Firebase token");
    } catch (error) {
      console.error("Error updating Firebase token");
    }

    return result;
  };

  const getFirebaseToken = async () => {
    const url = "https://firebase.buzz.ar/token.php";
    const response = await makeRequest("GET", url);
    if (response) {
      const result = JSON.parse(response as string);
      console.log(result);
      return result.idToken;
    }

    return "";
  };

  return (
    <form className="join">      
      <button className="text-button"
        onClick={(e) => {
          e.preventDefault();
          beginCallInitiation();
        }}
      >
        Call to client ID: {remoteUid}
      </button>
    </form>
  );
};
