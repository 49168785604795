import React, { useState } from "react";
import { ChannelForm } from "./components/ChannelForm";
import { VideoCall } from "./components/VideoCall";

import { createLazyClient as createRtmClient, RtmChannel, RtmMessage } from "agora-rtm-react";
import { StatusObject } from "./helpers/StatusObject";

//const appId: string = "9ae4fc0c4bfc4ba5a78a281229813d91";
const token: string | null = null;
const useRtmClient = createRtmClient();

interface AssistantOptions {
  appId?: string;
  remoteUid?: string;
  uid?: string;
  token?: string;
}

const App = () => {
  const [inCall, setInCall] = useState(false);
  //const [channelName, setChannelName] = useState("");
  const [rtmChannel, setRtmChannel] = useState(null as RtmChannel | null);
  const [firebaseToken, setFirebaseToken] = useState("");

  let appId = "";
  let uid = "";
  let remoteUid = "";

  const root = document.getElementById("assistant");

  if (root) {
    let val = root.getAttribute("data-appid");
    if (val !== null) {
      appId = val;
    }
    val = root.getAttribute("data-uid");
    if (val !== null) {
      uid = val;
    }
    val = root.getAttribute("data-remote-uid");
    if (val !== null) {
      remoteUid = val;
    }
  }

  const rtmClient = useRtmClient(appId);
  const statusObject = new StatusObject();

  return (
    <div className="call-area">
      <h3 className="heading"><img src={require('./logo2.png')} width="200" alt="Buzz.AR" /></h3>
      {inCall ? (
        <VideoCall
          setInCall={setInCall}
          appId={appId}
          token={token}
          uid={uid}
          remoteUid={remoteUid}
          rtmClient={rtmClient}
          rtmChannel={rtmChannel}
          firebaseToken={firebaseToken}
          statusObject={statusObject}
        />
      ) : (
        <ChannelForm
          setInCall={setInCall}
          appId={appId}
          token={token}
          uid={uid}
          remoteUid={remoteUid}
          rtmClient={rtmClient}
          rtmChannel={rtmChannel}
          setRtmChannel={setRtmChannel}
          firebaseToken={firebaseToken}
          setFirebaseToken={setFirebaseToken}
          statusObject={statusObject}
        />
      )}
    </div>
  );
};

export default App;
