export interface Vector2d {
  x: number;
  y: number;
}

let positions: Vector2d[] = [];

let alpha = 1;
let delta = 0.025;

export const drawPoint = (e: MouseEvent, canvas: HTMLCanvasElement) => {
  //var canvas = document.getElementById("player-canvas");
  var context = canvas.getContext("2d");
  //var rect = canvas.getBoundingClientRect();
  if (context) {
    
    const posx = e.offsetX;
    const posy = e.offsetY;

    positions.push({ x: posx, y: posy });

    context.fillStyle = "#00FF00";
    context.beginPath();
    context.arc(posx, posy, 5, 0, 2 * Math.PI);
    context.lineWidth = 3;
    context.closePath();
    context.fill();
  }
};

const drawAllPoints = (alpha: number) => {
  const canvas = document.getElementById("player-canvas");
  if (canvas && canvas instanceof HTMLCanvasElement) {
    const context = canvas.getContext("2d");
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);

      if (alpha >= delta) {
        context.globalAlpha = alpha;

        positions.forEach((pos) => {
          context.fillStyle = "#00F810";
          context.beginPath();
          context.arc(pos.x, pos.y, 5, 0, 2 * Math.PI);
          context.lineWidth = 3;
          context.closePath();
          context.fill();
        });
      } else {
        context.globalAlpha = 1;
      }
    }
  }
};

export const fadeOutAllPoints = () => {
  drawAllPoints(alpha);

  alpha -= delta;
  if (alpha > 0) {
    requestAnimationFrame(fadeOutAllPoints);
  } else {
    alpha = 1;
    positions = [];
  }
};
