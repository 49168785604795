import {
  AgoraVideoPlayer,
  createClient as createRtcClient,
  createMicrophoneAndCameraTracks,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-react";
import { RtmChannel, RtmClient } from "agora-rtm-react";

import React, { MouseEventHandler, ReactEventHandler, useEffect, useState } from "react";
import { StatusObject } from "../helpers/StatusObject";
import { drawPoint, fadeOutAllPoints, Vector2d } from "../helpers/Draw";

const config: ClientConfig = {
  mode: "rtc",
  codec: "vp8",
};

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useRtcClient = createRtcClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

export const VideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  appId: string;
  uid: string;
  remoteUid: string;
  token: string | null;
  rtmClient: RtmClient;
  rtmChannel: RtmChannel | null;
  firebaseToken: string;
  statusObject: StatusObject;
}) => {
  const { setInCall, appId, uid, remoteUid, token, rtmClient, rtmChannel, firebaseToken, statusObject } = props;
  const [remoteUser, setRemoteUser] = useState<IAgoraRTCRemoteUser | null>(null);
  const [start, setStart] = useState<boolean>(false);
  // using the hook to get access to the client object
  const rtcClient = useRtcClient();
  // ready is a state variable, which returns true when the local tracks are initialized, until then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  let points: Array<Vector2d> = [];
  let drawing = false;

  useEffect(() => {
    let init = async (name: string) => {
      rtcClient.on("user-published", async (user, mediaType) => {
        await rtcClient.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setRemoteUser(user);
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      rtcClient.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setRemoteUser(null);
        }
      });

      rtcClient.on("user-left", (user) => {
        console.log("leaving", user);
        setRemoteUser(null);
      });

      const userId = await rtcClient.join(appId, name, token, null);
      if (userId) {
        statusObject.rtcChannelJoined = true;
      }
      if (tracks) await rtcClient.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks && rtmChannel) {
      console.log("init ready");
      init(rtmChannel.channelId);
    }
  }, [rtcClient, ready, tracks, appId, token]);

  const afterChannelLeave = async () => {
    statusObject.rtcChannelJoined = false;
    rejectWithFirebase(remoteUid, firebaseToken);
    try {
      if (rtmChannel !== null && statusObject.rtmChannelJoined) {
        rtmChannel
          .leave()
          .then(() => {
            console.log("left succ");
            statusObject.rtmChannelJoined = false;
          })
          .catch(() => {
            console.error("left fail");
          });
        if (statusObject.rtmLoggedIn) {
          rtmClient.logout();
        }
      }
    } catch (error) {
      console.error("Leaving error:" + error);
    }
  };

  const rejectWithFirebase = (remoteUid: string, fToken: string) => {
    var url = `https://europe-west1-buzz-ar-assistant.cloudfunctions.net/sendReject?uid=${remoteUid}`;

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + fToken);
    //xhr.setRequestHeader("foo12", "bar12");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
      }
    };

    xhr.send();
  };

  const sendPoints = () => {
    fadeOutAllPoints();

    let pointsStr = "";
    points.forEach((point) => {
      if (pointsStr) {
        pointsStr += ",";
      }
      pointsStr += '{"x":"' + point.x.toFixed(4) + '","y":"' + point.y.toFixed(4) + '"}';
      //pointsStr += '{"x":"' + point.x.toFixed(4) + '","y":"0.5778"}';
    });
    pointsStr = "[" + pointsStr + "]";
    const cnt = points.length;
    points = [];

    const msg = '{"cmd":"7", "data":{"fromUserId":"2","fromUserName":"red","points":' + pointsStr + "}}";
    console.log("Sent " + cnt + " points: " + msg);

    sendChannelMessageRTM(msg);
  };

  const sendChannelMessageRTM = async (text: string) => {
    if (!rtmChannel) {
      return;
    }
    try {
      await rtmChannel.sendMessage({ text });
    } catch (error) {
      console.error("Send message error:" + error);
    }
  };

  const onFlushMarkers = async () => {
    points = [];
    sendPoints();
  }

  const onCanvasMouseMove = (e: React.MouseEvent) => {
    const maxPointsInChunk = 1000;

    const target = e.nativeEvent.target as HTMLCanvasElement;
    const width = target.clientWidth;
    const height = target.clientHeight;
    const x = (e.nativeEvent.offsetX * 2) / width - 1;
    const y = (e.nativeEvent.offsetY * 2) / height - 1;

    if (drawing) {
      drawPoint(e.nativeEvent, target);

      points.push({ x, y });
      if (points.length > maxPointsInChunk) {
        sendPoints();
      }
    }
  };

  const onCanvasMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    const target = e.nativeEvent.target as HTMLCanvasElement;
    const width = target.clientWidth;
    const height = target.clientHeight;
    const x = (e.nativeEvent.offsetX * 2) / width - 1;
    const y = (e.nativeEvent.offsetY * 2) / height - 1;

    points = [{ x, y }];
    drawing = true;
  };

  const onCanvasMouseUp = (e: React.MouseEvent) => {
    e.preventDefault();
    if (drawing) {
      drawing = false;
      sendPoints();
    }
  };

  return (
    <div className="App">
      {ready && tracks && (
        <Controls 
          tracks={tracks} 
          setStart={setStart} 
          setInCall={setInCall} 
          afterChannelLeave={afterChannelLeave} 
          onFlushMarkers={onFlushMarkers}
          />
      )}
      {start && tracks && (
        <Videos
          remoteUser={remoteUser}
          tracks={tracks}
          onCanvasMouseMove={onCanvasMouseMove}
          onCanvasMouseDown={onCanvasMouseDown}
          onCanvasMouseUp={onCanvasMouseUp}          
        />
      )}
    </div>
  );
};

const Videos = (props: {
  remoteUser: IAgoraRTCRemoteUser | null;
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
  onCanvasMouseMove: MouseEventHandler;
  onCanvasMouseDown: MouseEventHandler;
  onCanvasMouseUp: MouseEventHandler;  
}) => {
  const { remoteUser, tracks, onCanvasMouseMove, onCanvasMouseDown, onCanvasMouseUp } = props;

  return (
    <div>
      <AgoraVideoPlayer        
        className="local-video vid"
        videoTrack={tracks[1]}
        key="0"
      />
      {remoteUser !== null && remoteUser.videoTrack ? (
        <div id="remote-video-container">
          <canvas
            id="player-canvas"
            width="720"
            height="720"
            className="player-canvas"
            onMouseMove={onCanvasMouseMove}
            onMouseDown={onCanvasMouseDown}
            onMouseUp={onCanvasMouseUp}
          />
          <AgoraVideoPlayer            
            className={"remote-video player player-" + remoteUser.uid.toString()}
            videoTrack={remoteUser.videoTrack}
            id={"player-" + remoteUser.uid.toString()}
          />
        </div>
      ) : (
        <div id="remote-video">no remote video</div>
      )}
    </div>
  );
};

export const Controls = (props: {
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  afterChannelLeave: () => void;
  onFlushMarkers: () => Promise<void>;
}) => {
  const client = useRtcClient();
  const { tracks, setStart, setInCall, afterChannelLeave, onFlushMarkers } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type: "audio" | "video") => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };
  
  const leaveChannel = async () => {
    //await tracks[1].setEnabled(false);
    //await tracks[0].setEnabled(false);
    //tracks[0].stop();
    //tracks[1].stop();
    console.log("Video device disabling");

    // Switch the camera.
    tracks[1]
      .setEnabled(false)
      .then(() => {
        console.log("Video device disabled success");
      })
      .catch((e) => {
        console.log("Video device disabled", e);
      });

    console.log("Audio device disabling");
    tracks[0]
      .setEnabled(false)
      .then(() => {
        console.log("Audio device disabled success");
      })
      .catch((e) => {
        console.log("Audio device disabled", e);
      });

    await client.leave();
    client.removeAllListeners();
    // we close the tracks to perform cleanup
    setStart(false);
    setInCall(false);
    //tracks[0].close();
    //tracks[1].close();
    afterChannelLeave();
  };

  return (
    <div className="controls">
      <img src={require(trackState.audio ? '../assets/btn_mute_normal.png': '../assets/btn_unmute_normal.png')} width="60" alt="Mute Audio" className={trackState.audio ? "on" : ""} onClick={() => mute("audio")} />
      {<img src={require(trackState.audio ? '../assets/btn_endcall_normal.png': '../assets/btn_endcall_pressed.png')} width="75" alt="End Call" onClick={() => leaveChannel()} />}
      <img src={require('../assets/cancel220.png')} width="60" alt="Flush Markers" className="on" onClick={() => onFlushMarkers()} />        
      {/*<p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
        {trackState.video ? "MuteVideo" : "UnmuteVideo"}
  </p>*/}
    </div>
  );
};
